// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig =   {
    apiKey: "AIzaSyB6znF7jDzYiQeSCHGMIF0rLSL6qktnlrE",
    authDomain: "mymotheragency-208817.firebaseapp.com",
    databaseURL: "https://mymotheragency-208817.firebaseio.com",
    projectId: "mymotheragency-208817",
    storageBucket: "mymotheragency-208817.appspot.com",
    messagingSenderId: "332754035315",
    publishableKey: "pk_test_MMk6QzjFuJS3HgZIYTVAl9TI",
    appId: "1:332754035315:web:ac1f6b6ac054f1c3f68006"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);