import React, { useState, useEffect, useRef } from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
// import VpnKeyIcon from "@material-ui/icons/VpnKey";
import axios from "axios";
// import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useForm } from "react-hook-form";
import { FormGroup, Input } from "reactstrap";
// import DatePicker from "react-datepicker";
// import Glider from 'react-glider';
import "../../utils/firebase";
// import admin from  '../../utils/firebaseAdminUtils';
import { getDatabase, ref, set, push } from "firebase/database";
import {
  getAuth,
  createUserWithEmailAndPassword,
  getIdToken,
} from "firebase/auth";
// import StripeCheckout from "react-stripe-checkout";
import dayjs from "dayjs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import logo from "../../assets/out2.png";
import slideImg from "../../assets/img_step1.png";
import classicImg from "../../assets/classic.png";
import talentsImg from "../../assets/talent.png";
import "../Animation.css";
import "./FormTest.scss"; //Remove Test
import "react-datepicker/dist/react-datepicker.css";
import Popups from "../Checkout/Popups";
import Stripes from "../Checkout/Stripes";
import Paypal from "../Checkout/Paypal";
// import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "../Checkout/PopupJS.css";

import { useSearchParams } from "react-router-dom";
// import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { Stripe } from "stripe";

const stripePromise = loadStripe("pk_live_HAc3M5WIGeAtNCh5agGLRy77");
//PaneExample is assigned a value but never used
const PaneExample = ({ children, style, className }) => (
  <div className={`glider-slide ${className}`} style={style}>
    <h1>{children}</h1>
  </div>
);

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

function Forms(props) {
  const { t } = useTranslation();

  const isFirstRun = useRef(true);

  ////////////////////////////////////////////
  ////////        This is Hooks      ////////
  //////////////////////////////////////////

  //////////////////////////////
  ////    Hooks useState    ///
  ////////////////////////////

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [isValidVoucher, setIsValidVoucher] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [isItFinished, setIsItFinished] = useState(false);

  ////     Form 1      ////
  // const [lastName, setLastName] = useState('');
  // const [name, setName] = useState('');

  ////     Form 2      ////
  // const [email, setEmail] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  ////     Form 3      ////
  // GoogleMaps
  const [region, setRegion] = useState("");
  const [errorPosition, setErrorPosition] = useState(false);
  const [errorRegion, setErrorRegion] = useState(false); //errorrRegion is assigned and never used
  const [addresse, setAddresse] = useState("");
  const [errorAddresse, setErrorAddresse] = useState(false);

  const [gender, setGender] = useState("");

  const [dateNaissance, setDateNaissance] = useState();
  const [errorAge, setErrorAge] = useState(false);

  //// Final Forms Send
  const [finalResult, setFinalResult] = useState(false);
  const [cguBtn, setCguBtn] = useState(false);
  const [godfatherBtn, setGodfatherBtn] = useState(false);

  //const [isDataFromSocial, setDataFromSocial] = useState(false); // !!!! Set to false for prod

  /////////////////////////////////////////////////////////////////////////
  ////////////////        Social Data Verification        ////////////////
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////
  ////////    Fetching Data     ////////
  /////////////////////////////////////

  ///////////////////////////////////////////////////
  // Retrieve Data From React-Native throught URL //
  /////////////////////////////////////////////////

  const [searchParams] = useSearchParams();
  // console.log('Là search params uid', searchParams.get('uid'));
  // console.log('Là search params firstName', searchParams.get('firstName'));
  // console.log('Là search params lastName', searchParams.get('lastName'));

  // Axios.test
  // const [post, setPost] = useState({});

  // useEffect(() => {
  //     axios
  //         .get(`https://jsonplaceholder.typicode.com/todos/1`)
  //         // .get(`https://webi.tn/?uid=${data.uid}&firstName=${data.firstName}&lastName=${data.lastName}`)
  //         // .get(`https://example.com?uid=12345&firstName=Jacques&lastName=Chirac`, {
  //         //     headers: {

  //         //     }
  //         // })
  //         .then(res => {
  //             console.log('this is res from axios fetching data', res);
  //             setPost(res.data);
  //         })
  //         .catch(err => {
  //             console.log(err);
  //         })
  // }, []);

  // console.log('ici Params', props);

  ////  Url Params Check to set Ref for Social true of false
  //const isDataFromTheSocial = useRef(false);

  //  Init uid, firstName and lastName
  let uid = useRef(false);
  let firstName = useRef("");
  let lastName = useRef("");

  useEffect(() => {
    console.log("searchParams.get", searchParams.get("uid"));
    if (searchParams.get("uid") !== null) {
      // setDataFromSocial(true);
      // Or
      // DetectDataFromSocial();                                             //TOACTIVATE
      uid.current = true;
      //isDataFromTheSocial.current = true;
      if (searchParams.get("firstName") !== null) {
        firstName.current = searchParams.get("firstName");
      }
      if (searchParams.get("lastName") !== null) {
        lastName.current = searchParams.get("lastName");
      }
      if (searchParams.get("mail") !== null) {
        lastName.current = searchParams.get("mail");
      }
    }
  }, [searchParams]);

  // console.log('isDataFromTheSocial -> ici la ref du socialData', isDataFromTheSocial);
  // console.log('Url Params uid', uid.current);
  // console.log('Url Params firstName', firstName.current);
  // console.log('Url Params lastName', lastName);

  // Ne marche pas
  // let {dataFromSocial} = useParams();
  // console.log('Ici le useParams', dataFromSocial);

  // useEffect(() => {
  //     function handleDataFromSocial(value) {
  //         setDataFromSocial(value);
  //     }
  //     if(props.uid) {
  //         handleDataFromSocial(true)
  //     }
  //     // Indique comment nettoyer l'effet :
  //     // return function cleanup() {
  //     //     ChatAPI.unsubscribeFromFriendStatus(props.friend.id, handleStatusChange);
  //     // };
  // }, [props.uid]);

  //////////////////////////////////////////////////////
  ////////        Hook For BirthDay SetUp      ////////
  ////////////////////////////////////////////////////

  useEffect(() => {
    setDateNaissance(dayjs("2000-01-01").format("YYYY-MM-DD"));
    // axios.post('http://localhost:5000/mymotheragency-208817/us-central1/token/generate')
    // .then(res => {
    //     console.log(res.data)
    //     setToken(res.data)
    // })

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var birthDay = document.getElementById("birth");
  if (birthDay) {
    birthDay.addEventListener("change", function (e) {
      if (Date.parse(birthDay.value)) {
        setDateNaissance(birthDay.value);
        var year = birthDay.value.substr(0, 4);
        var current_year = new Date().getFullYear();
        var userAn = current_year - year;
        var theDivBirthError = document.getElementById("error_birth");
        theDivBirthError.innerHTML = "";
        if (userAn < 16) {
          /* console.log('Vous devez avoir plus de 16 ans');*/
          birthDay.value = "";
          theDivBirthError.innerHTML = "Vous devez avoir plus de 16 ans";
          e.preventDefault();
        }
      }
    });
  }

  // var dateControl = document.querySelector('input[type="date"]');
  // if (dateControl) {
  //     dateControl.addEventListener('click', function (e) {
  //         dateControl.value = "2000-01-01";
  //     })

  // }

  //////////////////////////////////////
  ////////     TaskBar CSS     ////////
  ////////////////////////////////////

  let [step, setStep] = useState(1);
  let opacity = {
    opacity: 0.1,
    pointerEvents: "none",
  };
  let style1 = {
    margin: "0px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    backgroundColor: "#e62375",
  };
  let style2 = {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    backgroundColor: "#e62375",
    animation: "progres 2s ease",
  };
  let style3 = {
    margin: "0px",
    borderRadius: "0px",
    backgroundColor: "#e62375",
    animation: "progres2 2s ease",
  };

  //////////////////////////////////////
  ////////     Media Query     ////////
  ////////////////////////////////////

  // Create a condition that targets viewports at least 768px tall
  const mediaQuery = window.matchMedia("(max-height: 768px)");

  function handleMediaQueryChange(e) {
    // Check if the media query is true
    if (e.matches) {
      var formin = document.getElementById("form_tow");
      var formin2 = document.getElementById("form_three");
      var formin3 = document.getElementById("form_four");
      var formin4 = document.getElementById("form_five");
      // var formin5 = document.getElementById("form_six");

      switch (step) {
        case 1:
          // !! Warning !! -> error when going to 769px to 768px to 769px => Second form not reappearing or generating an error
          // Trying to fix when formin is null with if statement
          if (formin) {
            formin.classList.add("hide");
          }
          break;
        case 2:
          formin.classList.remove("hide");
          //formin2.classList.add("hide");
          break;
        case 3:
          formin2.classList.remove("hide");
          //formin3.classList.add("hide");
          break;
        case 4:
          formin3.classList.remove("hide");
          //formin4.classList.add("hide");
          break;
        case 5:
          formin4.classList.remove("hide");
          //formin5.classList.add("hide");
          break;

        default:
          break;
      }
    }
  }

  // Register event listener
  mediaQuery.addListener(handleMediaQueryChange);

  // Initial check
  handleMediaQueryChange(mediaQuery);

  ///////////////////////////////////////////////////////////////////////////////////////////
  ////////        Function For Form increment animation, opacity and buttonClick    ////////
  /////////////////////////////////////////////////////////////////////////////////////////

  const arrowFunction = () => {
    var formin = document.getElementById("form_tow");
    var formin2 = document.getElementById("form_three");
    var formin3 = document.getElementById("form_four");
    var formin4 = document.getElementById("form_five");
    var formin5 = document.getElementById("form_six");

    switch (step) {
      // case (1 && searchParams.get('uid') !== null):
      case 1:
        document.getElementById("form1").click();
        // var formin = document.getElementById("form_tow");
        formin.classList.add("w3-animate-bottom");
        formin2.classList.add("overflowFlexHidden");
        break;
      case 2:
        document.getElementById("form2").click();
        // var formin2 = document.getElementById("form_three");
        formin2.classList.add("w3-animate-bottom");

        formin3.classList.add("overflowFlexHidden");
        break;
      case 3:
        document.getElementById("form3").click();
        // var formin3 = document.getElementById("form_four");
        formin3.classList.add("w3-animate-bottom");

        formin3.classList.remove("overflowFlexHidden");
        formin4.classList.add("overflowFlexHidden");
        break;
      // document.getElementById("form3").click();
      // // var formin3 = document.getElementById("form_four");
      // formin3.classList.add("w3-animate-bottom");

      // formin3.classList.add("overflowFlexHidden");
      // formin4.classList.add("overflowFlexHidden");
      // break;
      case 4:
        document.getElementById("form3").click();
        // var formin4 = document.getElementById("form_five");
        //formin3.classList.remove("overflowFlexHidden");
        formin4.classList.add("w3-animate-bottom");
        break;
      case 5:
        document.getElementById("form5").click();
        // var formin5 = document.getElementById("form_six");
        formin5.classList.add("w3-animate-bottom");
        break;

      default:
        break;
    }
  };

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////                What Do Forms                   ////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  //  Function Step Form Increment
  const changeStep = () => {
    setStep((value) => value + 1);
  };

  //  Function to set Form to "3" if uid === True && firstName && lastName
  const NumStepTo3 = () => {
    useEffect(() => {
      if (uid.current && firstName.current && lastName.current) {
        setStep((value) => 3);
        document.getElementById("numStep").innerText = 3;

        // ERROR HERE => Fix OVERFLOWFLEXHIDDEN
        var formin3 = document.getElementById("form_four");
        formin3.classList.add("overflowFlexHidden");
      }
    }, [uid, firstName, lastName]);
  };

  //  Function To Remove Arrow On Form 5
  const changeArrow = () => {
    if (step < 4) {
      document.getElementById("arrow").innerHTML = "&#8595;";

      return;
    }
    document.getElementById("arrow-suitch").style.display = "none";
  };

  //////////////////////////////////////////////////////////////////////
  ////////                Data Form Implementation             ////////
  ////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////
  ////////   Forms.Errors.Messages&Data    ////////
  ////////////////////////////////////////////////

  const {
    register: register1,
    formState: { errors: errors1 },
    handleSubmit: handleSubmit1,
  } = useForm();
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();
  const {
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm();
  const {
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm();

  ////    Form Data
  const [formData, setFormData] = useState({});
  const [subscriptions, setSubscriptions] = useState({});

  //  Hook to set formData with Social (Name + FirstName)!
  // const SetFormDataWithSocial = () => {
  //     useEffect(() => {
  //         // if (isDataFromSocial) setFormData();     // Store Data For Social Inside FormData
  //         if (isDataFromSocial) setFormData(propsData);

  //     }, [isDataFromSocial])
  // }

  ////////////////////////////////////////     TO EDIIIIIIIT     ////////////////////////////////////////

  //  Function Detect If Social ON                               //TOACTIVATE
  // const DetectDataFromSocial = () => {
  //     useEffect(() => {
  //         if (fromSocialLogIn) setDataFromSocial((value) => true);

  //     })
  // };

  let onSubmit1 = null;
  let onSubmit2 = null;
  let onSubmit3 = null;
  let onSubmit4 = null;

  ////    Check If Social OK and if firstName and lastName to submit forms (Step 1 or Step 3 ?)

  if (uid.current && firstName.current === "" && lastName.current === "") {
    // Data retrieve from Social Login
    let userUid = searchParams.get("uid");

    onSubmit1 = (data) => {
      setFormData((result) => {
        // Ici Data Form 1 + console.log to delete
        console.log(
          "ici la Data form1 de AppleConnectFail",
          data,
          "+ result",
          result
        );
        return { ...result, ...data, uid: userUid };
      });
      changeStep();
      changeArrow();
      document.getElementById("numStep").innerText = step + 1;
    };
    onSubmit2 = (data) => {
      setFormData((result) => {
        return { ...result, ...data };
      });
      changeStep();
      changeArrow();
      document.getElementById("numStep").innerText = step + 1;
    };
    onSubmit3 = (data) => {
      if (addresse === "") {
        setErrorAddresse(true);
        return;
      }
      const _age = dayjs().diff(dayjs(dateNaissance), "year");
      if (_age < 16) {
        setErrorAge(true);
        return;
      }
      setErrorAge(false);

      document.getElementById("iconClick").click();
      if (errorPosition) {
        return;
      }
      if (region === "") {
        setErrorRegion(true);
        return;
      }

      setFormData((result) => {
        return {
          ...result,
          ...data,
          region,
          dateNaissance: dayjs(dateNaissance).unix(),
          gender: gender,
        };
      });
      changeStep();
      changeArrow();
      document.getElementById("numStep").innerText = step + 1;
      // var formin3 = document.getElementById("form_four");
      // formin3.classList.remove("overflowFlexHidden");
    };
    onSubmit4 = (data) => {
      data.Godfather = godfatherBtn ? true : false;
      data.cgu = cguBtn ? true : false;
      if (!cguBtn) {
        return;
      }
      setFormData((result) => {
        return { ...result, ...data, dataTest };
      });
      // changeStep();
      // changeArrow();
      // document.getElementById("numStep").innerText = step + 1;
      // setFinalResult(true)
    };
  } else if (
    searchParams.get("uid") &&
    searchParams.get("firstName") &&
    searchParams.get("lastName")
  ) {
    // Data retrieve from Social Login
    let userUid = searchParams.get("uid");
    let emailFromUrl = searchParams.get("mail");

    //SetFormDataWithSocial();
    NumStepTo3();

    onSubmit3 = (data) => {
      if (addresse === "") {
        setErrorAddresse(true);
        return;
      }
      const _age = dayjs().diff(dayjs(dateNaissance), "year");
      if (_age < 16) {
        setErrorAge(true);
        return;
      }
      setErrorAge(false);

      document.getElementById("iconClick").click();
      if (errorPosition) {
        return;
      }
      if (region === "") {
        setErrorRegion(true);
        return;
      }

      setFormData((result) => {
        return {
          ...result,
          ...data,
          region,
          dateNaissance: dayjs(dateNaissance).unix(),
          gender: gender,
          uid: userUid,
          email: emailFromUrl,
        };
      });
      changeStep();
      changeArrow();
      document.getElementById("numStep").innerText = step + 1;
      // var formin3 = document.getElementById("form_four");
      // formin3.classList.remove("overflowFlexHidden");
    };
    onSubmit4 = (data) => {
      data.Godfather = godfatherBtn ? true : false;
      data.cgu = cguBtn ? true : false;
      if (!cguBtn) {
        return;
      }
      setFormData((result) => {
        return { ...result, ...data, dataTest };
      });
      // changeStep();
      // changeArrow();
      // document.getElementById("numStep").innerText = step + 1;
      // setFinalResult(true)
    };
  } else {
    onSubmit1 = (data) => {
      setFormData((result) => {
        // Ici Data Form 1 + console.log to delete
        console.log(
          "ici la Data form1 du canal standard",
          data,
          "+ result",
          result
        );
        return { ...result, ...data };
      });
      changeStep();
      changeArrow();
      document.getElementById("numStep").innerText = step + 1;
    };
    onSubmit2 = (data) => {
      setFormData((result) => {
        return { ...result, ...data };
      });
      changeStep();
      changeArrow();
      document.getElementById("numStep").innerText = step + 1;
    };
    onSubmit3 = (data) => {
      if (addresse === "") {
        setErrorAddresse(true);
        return;
      }
      const _age = dayjs().diff(dayjs(dateNaissance), "year");
      if (_age < 16) {
        setErrorAge(true);
        return;
      }
      setErrorAge(false);

      document.getElementById("iconClick").click();
      if (errorPosition) {
        return;
      }
      if (region === "") {
        setErrorRegion(true);
        return;
      }

      setFormData((result) => {
        return {
          ...result,
          ...data,
          region,
          dateNaissance: dayjs(dateNaissance).unix(),
          gender: gender,
        };
      });
      changeStep();
      changeArrow();
      document.getElementById("numStep").innerText = step + 1;
      // var formin3 = document.getElementById("form_four");
      // formin3.classList.remove("overflowFlexHidden");
    };
    onSubmit4 = (data) => {
      data.Godfather = godfatherBtn ? true : false;
      data.cgu = cguBtn ? true : false;
      if (!cguBtn) {
        return;
      }
      setFormData((result) => {
        return { ...result, ...data, dataTest };
      });
      // changeStep();
      // changeArrow();
      // document.getElementById("numStep").innerText = step + 1;
      // setFinalResult(true)
    };
  }

  //////////////////////////////////////////////////////////////////////
  ////////        Implementation de la Data GoToForm -> 3      ////////
  ////////////////////////////////////////////////////////////////////

  ////////    !!!!!!!!     PlaceHolder Social DATA     !!!!!!!!    ////////
  const propsData = {
    idTokenFb: "FbToken00",
    idTokenGoogle: "GoogleToken01",
    idTokenApple: "AppleToken02",
    firstName: "Jose",
    lastName: "Bové",
    email: "JoseBove@gmail.com",
  };

  let dataTest = {
    users: {
      cgu: true,
      godfather: {
        amount: 10,
        tt: "",
      },
      godfatherAmount: 5,
      group: "default",
      hasCompletedSubscribe: true,
      isRefunded: false,
      trialPeriod: 1,
      paymentValidation: false,
      TalentLab: false,
    },
    data: {
      models: {
        address: {
          description: "",
          matched_substrings: [
            {
              length: null,
              offset: null,
            },
          ],
          place_id: "",
          reference: "",
          structured_formatting: {
            main_text: "",
            main_text_matched_substrings: [
              {
                length: null,
                offset: null,
              },
            ],
            secondary_text: "",
          },
          terms: [
            {
              offset: null,
              value: "",
            },
            {
              offset: null,
              value: "",
            },
            {
              offset: null,
              value: "",
            },
            {
              offset: null,
              value: "",
            },
          ],
          types: ["", ""],
        },
        addressPackage: {
          city: "",
          country: "",
          countryCode: "",
          lat: null,
          lng: null,
        },
        atypicalNatures: ["originalHair", "freckles"],
        birthday: "",
        details: {
          bottomConfection: null,
          chest: null,
          crotch: null,
          ethnicity: "",
          eyeColor: "",
          hairColor: "",
          hairLength: "",
          hairType: "",
          height: null,
          hips: null,
          shoesSlide: null,
          topConfection: null,
          waist: null,
        },
        email: "",
        firstName: "",
        gender: "",
        godfatherUid: "",
        hasCompletedProfile: true,
        lastName: "",
        looks: [""],
        mobile: 0,
        musicInstruments: [""],
        nationalities: [
          {
            key: "",
            label: "",
          },
        ],
        profile: {
          //   200 : {
          //     date : null,
          //     disabled : false,
          //     fileName : "",
          //     url : ""
          //   },
          //   300 : {
          //     date : null,
          //     disabled : false,
          //     fileName : "",
          //     url : ""
          //   },
          //   400 : {
          //     date : 1646044459,
          //     disabled : false,
          //     fileName : "Story quest4 (1)-1.png",
          //     url : "https://lh3.googleusercontent.com/jRtC9KRvAUgbu4OHt6dLhXjYIb1WHZs_nWkqZeU45JXS10nVyjt1EVYakLLjdJ5TweWkKLaApZmbvUWDgLu_YZTHwrOMz2NGhcG8qtD6qXtZHeF7HXA=w400-h400-c-pp-l100-rj"
          //   },
          //   "1000" : {
          //     "date" : 1646044459,
          //     "disabled" : false,
          //     "fileName" : "Story quest4 (1)-1.png",
          //     "url" : "https://lh3.googleusercontent.com/jRtC9KRvAUgbu4OHt6dLhXjYIb1WHZs_nWkqZeU45JXS10nVyjt1EVYakLLjdJ5TweWkKLaApZmbvUWDgLu_YZTHwrOMz2NGhcG8qtD6qXtZHeF7HXA=w1000-h1000-c-pp-l100-rj"
          //   },
          //   "1500" : {
          //     "date" : 1646044459,
          //     "disabled" : false,
          //     "fileName" : "Story quest4 (1)-1.png",
          //     "url" : "https://lh3.googleusercontent.com/jRtC9KRvAUgbu4OHt6dLhXjYIb1WHZs_nWkqZeU45JXS10nVyjt1EVYakLLjdJ5TweWkKLaApZmbvUWDgLu_YZTHwrOMz2NGhcG8qtD6qXtZHeF7HXA=w1500-h1500-c-pp-l100-rj"
          //   },
          //   "original" : {
          //     "date" : 1646044459,
          //     "disabled" : false,
          //     "fileName" : "Story quest4 (1)-1.png",
          //     "url" : "https://storage.googleapis.com/mymotheragency-208817.appspot.com/images%2FZQmATwZy6PeMADhnGYqZ4oNTqwm2%2Fprofile%2Fbw3_ef018566-ccfd-495d-9443-2123cbfdfd60_Story%20quest4%20(1)-1.png"
          //   }
        },
        qualifications: ["model"],
        representedByAnAgency: false,
        spokenLanguage: [
          {
            key: "",
            label: "",
          },
        ],
        sports: [""],
        submittedProperties: {
          address: true,
          atypicalNatures: true,
          bottomConfection: true,
          chest: true,
          crotch: true,
          ethnicity: true,
          experiences: false,
          eyeColor: true,
          hairColor: true,
          hairLength: true,
          hairType: true,
          height: true,
          hips: true,
          jobZones: false,
          jobZonesCountries: false,
          looks: true,
          musicInstruments: true,
          portfolioLink: false,
          pseudo: false,
          qualifications: true,
          shoesSlide: true,
          spokenLanguage: true,
          sports: true,
          topConfection: true,
          transport: true,
          waist: true,
          worldWide: true,
        },
        transport: {
          transportMoto: true,
        },
        worldWide: true,
        MMPartner: "",
      },
    },
  };
  // console.log('Je suis le dataTest à envoyer à Firebase', dataTest);

  console.log("this is the formData", formData);

  // // Reception de la data de React Native
  // let { dataProps } = useParams();
  // Soit
  // Via injectedJavaScript -> https://dev.to/mukeshmandiwal/webview-data-flow-with-webviews-in-a-react-native-app-and-navigation-4396
  // Let's implement

  const form1And2 = (
    <>
      <div className="formOne forms w3-animate-bottom" hidden={step > 1}>
        <div id="formOneName">
          <span className="stepNumber">01</span>
          <h2>{t("Register.welcomeMsg")}</h2>
          <h2>{t("Register.firstStep")}</h2>
        </div>
        <form onSubmit={handleSubmit1(onSubmit1)}>
          <FormGroup row>
            <div className="inputContainer">
              <input
                name="lastName"
                type="text"
                {...register1("lastName", { required: true })}
              />
              <div className="label-champ">{t("Register.nameHint")}</div>
              <PersonOutlineIcon className="icon" />
              <div className="label">{t("Register.name")}</div>
            </div>
            <div className="error">
              {errors1.lastName && t("Register.nameError")}
            </div>
            <div className="inputContainer">
              <Input
                name="firstName"
                type="text"
                {...register1("firstName", { required: true })}
              />
              <div className="label-champ">{t("Register.lastNameHint")}</div>
              <div className="label">{t("Register.lastName")}</div>
            </div>
            <div className="error">
              {errors1.firstName && t("Register.lastNameError")}
            </div>
          </FormGroup>
          <button type="submit" className="hideButton" id="form1"></button>
        </form>
      </div>
      <div
        id="form_tow"
        className="formTwo forms"
        hidden={step > 2}
        style={step === 1 ? opacity : null}
      >
        <div id="formTwoName">
          <span className="stepNumber">02</span>
          <h2>{t("Register.secondStep")}</h2>
        </div>
        <form onSubmit={handleSubmit2(onSubmit2)}>
          <FormGroup>
            <div className="inputContainer">
              <Input
                type="email"
                name="Email"
                {...register2("email", {
                  required: t("Register.emailError"),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("Register.emailInvalid"),
                  },
                })}
              />
              <div className="label-champ">{t("Register.emailHint")}</div>
              <div className="error">
                {errors2.email ? errors2.email && errors2.email.message : null}
              </div>
              <MailOutlineIcon className="icon" />
              <div className="label">{t("Register.email")}</div>
            </div>
            <div hidden={step === 1} className="inputContainer">
              <Input
                type={passwordShown ? "text" : "password"}
                name="password"
                pattern="^(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
                {...register2("password", { required: true })}
              />
              <div className="label-champ"> {t("Register.passwordHint")}</div>
              {passwordShown ? (
                <VisibilityIcon onClick={togglePassword} className="icon" />
              ) : (
                <VisibilityOffIcon onClick={togglePassword} className="icon" />
              )}
              <div className="label">{t("Register.password")}</div>
            </div>
            <div className="error">
              {" "}
              {errors2.password && t("Register.passwordError")}
            </div>
          </FormGroup>
          <button id="form2" className="hideButton"></button>
        </form>
      </div>
    </>
  );

  ////////////////////////////////////////////////////////
  ////////        Google Map Implementation      ////////
  //////////////////////////////////////////////////////

  ////////        Default Map Position      ////////
  let [center, setDefaultProps] = useState({
    lat: 48.831617,
    lng: 2.37854,
  });

  //https://positionstack.com/
  const apiKey = "14f426956b383729d0745089ace48115";
  //google api Key
  const googleApiKey = "AIzaSyB_UnFeiA5IUUYaYpCl35Mg6CF9WJ0w--c";
  console.log(googleApiKey);

  const _onClick = ({ x, y, lat, lng, event }) => {
    axios
      .get(
        `http://api.positionstack.com/v1/reverse?access_key=${apiKey}&query=${lat},${lng}`
      )
      .then((res) => {
        setRegion(`${res.data.data[0].region}, ${res.data.data[0].country}`);
        setErrorRegion(false);
      });
  };
  const findPays = () => {
    let country = document.getElementById("country").value;
    axios
      .get(
        `http://api.positionstack.com/v1/forward?access_key=${apiKey}&query= ${country}`
      )
      .then((response) => {
        if (response.data.data[0]) {
          setErrorPosition(false);
          setDefaultProps({
            lat: response.data.data[0].latitude,
            lng: response.data.data[0].longitude,
          });
        } else {
          setErrorPosition(true);
          console.log("here error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (address) => {
    setAddresse(address);
    setErrorAddresse(false);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setAddresse(address);
        setErrorAddresse(false);
      })
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    setRegion(addresse);
  }, [addresse]);

  ///////////////////////////////////////////////////////////////
  ////////          Gender Selection function           ////////
  /////////////////////////////////////////////////////////////

  const handleSelectGender = (e) => {
    setGender(e.target.value);
  };

  ///////////////////////////////////////////////////////////////
  ////////        FinalForm Data to send to Firebase    ////////
  /////////////////////////////////////////////////////////////

  const submitForm = () => {
    // console.log(formData)
    // alert(JSON.stringify(formData));
  };

  const submitFormFinal = () => {
    alert(JSON.stringify(formData));
  };
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  /*Use like so*/

  async function timeSensativeAction() {
    //must be async func
    //do something here
    await sleep(5000); //wait 5 seconds
    //continue on...
  }
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (finalResult) {
      ////////////////////////////
      ////    Test Canals    ////
      //////////////////////////

      // Get Firebase Database
      const db = getDatabase();

      // Get Firebase Auth for User and UID
      // const auth = getAuth();

      // Setting Up Relation between Collection and User
      const usersRef = ref(db, "users");
      const modelsRef = ref(db, "data/models");
      const newUserRef = push(usersRef);
      const newModelRef = push(modelsRef);
      //dataTest.data.models.MMPartner = "Africa"
      if (uid.current && firstName.current === "" && lastName.current === "") {
        console.log("This is FinalData from Only UID Path");

        // Setting UID for current Path
        let userUid = searchParams.get("uid");

        dataTest.users.createdAt = dayjs().unix();
        dataTest.data.models.createdAt = dayjs().unix();
        // formData.hasCompletedSubscribe = true;
        // formData.isRefunded = false;
        delete formData.password;

        //  Set DataTest with subscription
        dataTest.users.subscriptions = subscriptions;

        //  Set dataTest with User formData
        dataTest.data.models.birthday = formData.dateNaissance;
        dataTest.data.models.email = formData.email;
        dataTest.data.models.firstName = formData.firstName;
        dataTest.data.models.lastName = formData.lastName;
        dataTest.data.models.gender = formData.gender;

        // dataTest.data.models = {
        //     birthday : formData.dateNaissance,
        //     email : formData.email,
        //     firstName : formData.firstName,
        //     lastName : formData.lastName,
        // };
        console.log(
          "Je suis le dataTest POPULATE à envoyer à Firebase",
          dataTest
        );

        getAuth()
          .getUser(userUid)
          .then((userRecord) => {
            // See the UserRecord reference doc for the contents of userRecord.
            console.log(
              `Successfully fetched user data: ${userRecord.toJSON()}`
            );

            const user = userRecord;

            // dataTest.users.createdAt = dayjs().unix();
            // // formData.hasCompletedSubscribe = true;
            // // formData.isRefunded = false;
            // delete formData.password;

            // //  Set DataTest with subscription
            // dataTest.users.subscriptions = subscriptions;

            // //  Set dataTest with User formData
            // dataTest.data.models.birthday = formData.dateNaissance;
            // dataTest.data.models.email = formData.email;
            // dataTest.data.models.firstName = formData.firstName;
            // dataTest.data.models.lastName = formData.lastName;
            // dataTest.data.models.gender = formData.gender;
            // // dataTest.data.models = {
            // //     birthday : formData.dateNaissance,
            // //     email : formData.email,
            // //     firstName : formData.firstName,
            // //     lastName : formData.lastName,
            // // };
            // console.log('Je suis le dataTest POPULATE à envoyer à Firebase', dataTest);

            if (user) {
              getIdToken(user).then((token) => {
                set(ref(db, "users/" + userUid), dataTest.users);
                set(ref(db, "data/models/" + userUid), dataTest.data.models);
                set(
                  ref(db, "users/" + userUid + "/subscriptions"),
                  dataTest.users.subscriptions
                );

                // Doublon de Aymen pour test la data sur Firebase
                // set(ref(db,'data/models/'+ user.uid + '/subscriptions'), dataTest.users.subscriptions);

                console.log("Account Created");
                setFinalResult(false);
                timeSensativeAction();
                window.ReactNativeWebView.postMessage(token);
                // const data = {
                //     uid:user.uid
                // }
                // axios.post('https://us-central1-mymotheragency-208817.cloudfunctions.net/token/generate', data)
                // .then(res => {
                //     window.location.replace(`https://app.mymotheragency.com/signin-token?token=${res.data}`)
                // })
              });
            } else {
              set(newUserRef, dataTest.users);
              set(newModelRef, dataTest.data.models);
            }
          })
          .catch((error) => {
            console.log("Error fetching user data:", error);
          });
      } else if (
        searchParams.get("uid") &&
        searchParams.get("firstName") &&
        searchParams.get("lastName")
      ) {
        console.log("This is FinalData from UID and Names Path");

        // Setting UID for current Path
        let userUid = searchParams.get("uid");

        dataTest.users.createdAt = dayjs().unix();
        dataTest.data.models.createdAt = dayjs().unix();
        // formData.hasCompletedSubscribe = true;
        // formData.isRefunded = false;
        delete formData.password;

        //  Set DataTest with subscription
        dataTest.users.subscriptions = subscriptions;

        //  Set dataTest with User formData
        // dataTest.data.models.birthday = formData.dateNaissance;
        dataTest.data.models.email = searchParams.get("mail");
        // dataTest.data.models.firstName = formData.firstName;
        // dataTest.data.models.lastName = formData.lastName;
        dataTest.data.models.gender = formData.gender;

        console.log(
          "Je suis le dataTest POPULATE à envoyer à Firebase",
          dataTest
        );

        set(ref(db, "users/" + userUid), dataTest.users);
        set(ref(db, "data/models/" + userUid), dataTest.data.models);
        set(
          ref(db, "users/" + userUid + "/subscriptions"),
          dataTest.users.subscriptions
        );

        setFinalResult(false);
        //window.ReactNativeWebView.postMessage(token);
      } else {
        console.log("This is FinalData from normal SignUp Path");

        const auth = getAuth();

        createUserWithEmailAndPassword(auth, formData.email, formData.password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;

            dataTest.users.createdAt = dayjs().unix();
            dataTest.data.models.createdAt = dayjs().unix();
            // formData.hasCompletedSubscribe = true;
            // formData.isRefunded = false;
            delete formData.password;
            dataTest.users.trialPeriod = 1;
            //  Set DataTest with subscription
            ////REMETTRE LES 3 CI-DESSOUS QUAND VIA STRIPE ET PAS LE VOUCHER
            // use "===" to compare the value of the subscription
            if (subscriptions.type === "stripe")
              dataTest.users.stripeCustomerId = formData.stripeCustomerId;
            if (
              subscriptions.type === "stripe" ||
              subscriptions.type == "paypal"
            ) {
              dataTest.users.subscriptions = subscriptions;
              dataTest.users.paymentValidation = true;
              dataTest.users.paymentValidationRequired = false;
              dataTest.users.paymentValidationAt = dayjs()
                .add(1, "month")
                .valueOf();
            }

            //  Set dataTest with User formData
            dataTest.data.models.birthday = formData.dateNaissance;
            dataTest.data.models.email = formData.email;
            dataTest.data.models.firstName = formData.firstName;
            dataTest.data.models.lastName = formData.lastName;
            dataTest.data.models.gender = formData.gender;
            // dataTest.data.models = {
            //     birthday : formData.dateNaissance,
            //     email : formData.email,
            //     firstName : formData.firstName,
            //     lastName : formData.lastName,
            // };
            console.log(
              "Je suis le dataTest POPULATE à envoyer à Firebase",
              dataTest
            );

            if (user) {
              getIdToken(user).then(() => {
                set(ref(db, "users/" + user.uid), dataTest.users);
                set(ref(db, "data/models/" + user.uid), dataTest.data.models); // manque catégorie expérience expérience
                // set(ref(db,'users/'+ user.uid + '/subscriptions'), dataTest.users.subscriptions); // OK

                push(
                  ref(db, "users/" + user.uid + "/subscriptions"),
                  subscriptions
                );
                set(ref(db, "groups/model/users/" + user.uid), true);

                // Doublon de Aymen pour test la data sur Firebase
                // set(ref(db,'data/models/'+ user.uid + '/subscriptions'), dataTest.users.subscriptions);

                console.log("Account Created");
                setFinalResult(false);
                timeSensativeAction();
                // window.ReactNativeWebView.postMessage(token);
                const data = {
                  uid: user.uid,
                };
                axios
                  .post(
                    "https://us-central1-mymotheragency-208817.cloudfunctions.net/token/generate",
                    data
                  )
                  .then((res) => {
                    window.ReactNativeWebView.postMessage(res.data);
                  });
              });
            } else {
              set(newUserRef, dataTest.users);
              set(newModelRef, dataTest.data.models);
            }
            // alert('Compte Créer');
            // const data = {
            //     uid:user.uid
            // }
            // axios.post('https://us-central1-mymotheragency-208817.cloudfunctions.net/token/generate', data)
            // .then(res => {
            //     window.location.replace(`https://app.mymotheragency.com/signin-token?token=${res.data}`)
            // })
            // ...
          })
          .catch((error) => {
            console.log(error);
            // ..
          });
      }

      //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
      ////    This is the base Aymen Setup    \\\\
      //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
      /*
      const db = getDatabase();
      const auth = getAuth();
      const usersRef = ref(db,'users');
      const modelsRef = ref(db,'data/models');
      const newUserRef = push(usersRef);
      const newModelRef = push(modelsRef);

      console.log('db', db);
      console.log('auth', auth);
      console.log('usersRef', usersRef);
      console.log('modelsRef', modelsRef);
      console.log('newUserRef', newUserRef);
      console.log('newModelRef', newModelRef);
      */
      // test For ALL for ONE Data !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // -> Implemented throught the legacy code of the inscription tunnel
      /*
      const [firstName,setFirstName] = useState(); 
          setFirstName: firstName => ({ type: 'SUBSCRIBE/SETFIRSTNAME', payload: firstName });
      const [lastName, setLastName] = useState(); 
          setLastName: lastName => ({ type: 'SUBSCRIBE/SETLASTNAME', payload: lastName });
      const [mobile, setMobile] = useState(); 
          setMobile: mobile => ({ type: 'SUBSCRIBE/SETMOBILE', payload: mobile });
      const [email, setEmail] = useState(); 
          setEmail: email => ({ type: 'SUBSCRIBE/SETEMAIL', payload: email });
      const [godfatherUid, setGodfatherUid] = useState(); 
          setGodfatherUid: godfatherUid => ({ type: 'SUBSCRIBE/SETGODFATHERUID', payload: godfatherUid });
      const [voucherUid, setVoucherUid] = useState(); 
          setVoucherUid: voucherUid => ({ type: 'SUBSCRIBE/SETVOUCHERUID', payload: voucherUid });
      const [password, setPassword] = useState(); 
          setPassword: password => ({ type: 'SUBSCRIBE/SETPASSWORD', payload: password });
      const [birthday, setBirthday] = useState(); 
          setBirthday: birthday => ({ type: 'SUBSCRIBE/SETBIRTHDAY', payload: birthday });
      const [gender, setGender] = useState(); 
          setGender: gender => ({ type: 'SUBSCRIBE/SETGENDER', payload: gender });
      const [nationalities, setNationalities] = useState(); 
          setNationalities: nationalities =>
        ({ type: 'SUBSCRIBE/SETNATIONALITIES', payload: nationalities });
      const [company, setCompany] = useState(); 
          setCompany: company => ({ type: 'SUBSCRIBE/COMPANYSUBSCRIBE/SETCOMPANY', payload: company });
      const [companyAddress, setCompanyAddress] = useState(); 
          setCompanyAddress: companyAddress => ({ type: 'SUBSCRIBE/COMPANYSUBSCRIBE/SETCOMPANYADDRESS', payload: companyAddress });
      const [website, setWebsite] = useState(); 
          setWebsite: website => ({ type: 'SUBSCRIBE/SETWEBSITE', payload: website });
      const [representedByAnAgency, setRepresentedByAnAgency] = useState(); 
          setRepresentedByAnAgency: representedByAnAgency =>
        ({ type: 'SUBSCRIBE/SETREPRESENTEDBYANAGENCY', payload: representedByAnAgency });
      const [work, setWork] = useState(); 
          setWork: work => ({ type: 'SUBSCRIBE/SETWORK', payload: work });
      const [country, setCountry] = useState(); 
          setCountry: country => ({ type: 'SUBSCRIBE/SETCOUNTRY', payload: country });
      const [provider, setProvider] = useState(); 
          setProvider: provider => ({ type: 'SUBSCRIBE/SETPROVIDER', payload: provider });
      const [tt, setTt] = useState(); 
          setTt: tt => ({ type: 'SUBSCRIBE/SETTT', payload: tt });
      const [page, setPage] = useState(); 
          setPage: page => ({ type: 'SUBSCRIBE/SETPAGE', payload: page });
      const [loading, setLoading] = useState(); 
          setLoading: loading => ({ type: 'SUBSCRIBE/SETLOADING', payload: loading });
      const [mode, setMode] = useState(); 
          setMode: mode => ({ type: 'SUBSCRIBE/SETMODE', payload: mode });
      const [params, setTrackingParams] = useState(); 
          setTrackingParams: params => ({ type: 'SUBSCRIBE/SETTRACKINGPARAMS', payload: params });
      */
      /*
      createUserWithEmailAndPassword(auth, formData.email, formData.password)
      .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log('ici userCredential and user', userCredential, ' ', userCredential.user);
          formData.createdAt = dayjs().toISOString();
          formData.hasCompletedSubscribe = true;
          formData.isRefunded = false;
          delete formData.password;

          //  Set DataTest with subscription
          dataTest.users.subscriptions = subscriptions;

          //  Set dataTest with User formData
          dataTest.data.models.birthday = formData.dateNaissance;
          dataTest.data.models.email = formData.email;
          dataTest.data.models.firstName = formData.firstName;
          dataTest.data.models.lastName = formData.lastName;
          dataTest.data.models.gender = formData.gender;
          // dataTest.data.models = {
          //     birthday : formData.dateNaissance,
          //     email : formData.email,
          //     firstName : formData.firstName,
          //     lastName : formData.lastName,
          // };
          console.log('Je suis le dataTest POPULATE à envoyer à Firebase', dataTest);


          if(user) {
              getIdToken(user)
              .then(token => {
                  set(ref(db,'users/'+ user.uid), dataTest.users);
                  set(ref(db,'data/models/'+ user.uid), dataTest.data.models);
                  set(ref(db,'users/'+ user.uid + '/subscriptions'), dataTest.users.subscriptions);

                  // Doublon de Aymen pour test la data sur Firebase
                  // set(ref(db,'data/models/'+ user.uid + '/subscriptions'), dataTest.users.subscriptions);

                  console.log('Account Created');
                  setFinalResult(false);
                  window.ReactNativeWebView.postMessage(token);
                  // const data = {
                  //     uid:user.uid
                  // }
                  // axios.post('https://us-central1-mymotheragency-208817.cloudfunctions.net/token/generate', data)
                  // .then(res => {
                  //     window.location.replace(`https://app.mymotheragency.com/signin-token?token=${res.data}`)
                  // })
              })
          } else {
              set(newUserRef, dataTest.users);
              set(newModelRef, dataTest.data.models);
          }
          // alert('Compte Créer');
          // const data = {
          //     uid:user.uid
          // }
          // axios.post('https://us-central1-mymotheragency-208817.cloudfunctions.net/token/generate', data)
          // .then(res => {
          //     window.location.replace(`https://app.mymotheragency.com/signin-token?token=${res.data}`)
          // })
          // ...
      })
      .catch((error) => {
          console.log(error)
          // ..
      });
      */

      // push(ref(db, 'users/'), {
      //     username: 'named'
      // });

      // UsersRef.setValue(formData)
      // onValue(UsersRef, (snapshot) => {
      //     console.log('object')
      // const data = snapshot.val();
      // console.log(data)
      // });
    }
  }, [finalResult]);

  //////////////////////////////////////////////////////////////////
  ////////                 Voucher Validation              ////////
  ////////////////////////////////////////////////////////////////

  const _checkVoucher = (e) => {
    axios
      .get(
        `https://app.mymotheragency.com/api/voucher/isvalid/${e.target.value}`
      )
      .then((res) => {
        if (res.data.isValid) {
          errors4.voucher = false;
          if (active) {
            if (res.data.talentLab) {
              setIsValidVoucher(true);
              setVoucher(e.target.value);
            } else {
              errors4.voucher = true;
              setIsValidVoucher(false);
              setVoucher("");
            }
          } else {
            setIsValidVoucher(true);
            setVoucher(e.target.value);
          }
        } else {
          errors4.voucher = true;
          setIsValidVoucher(false);
          setVoucher("");
        }
      })
      .catch((error) => console.error("Error", error));
  };

  const sendVoucher = () => {
    dataTest.users.trialPeriod = 9999;
    let beginAt = new Date();
    let endAt = beginAt;
    setSubscriptions((prev) => ({
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      beginAt: new Date(beginAt).getTime(),
      endAt: new Date(endAt.setMonth(endAt.getMonth() + 12)).getTime(),
      type: "voucher",
      subscription: { voucherUid: voucher },
    }));
    if (Africa.includes(formData.region))
      dataTest.data.models.MMPartner = "Africa";
    else if (SouthAsia.includes(formData.region))
      dataTest.data.models.MMPartner = "India";
    else if (NorthAmerica.includes(formData.region))
      dataTest.data.models.MMPartner = "America";

    if (active) {
      dataTest.users.TalentLab = true;
    }
    // subscriptions.createdAt = new Date().getTime();
    // subscriptions.updatedAt = new Date().getTime();
    // subscriptions.beginAt = new Date(beginAt).getTime();
    // subscriptions.endAt = new Date(endAt.setMonth(endAt.getMonth() + 12)).getTime();
    // subscriptions.type = 'voucher';
    // subscriptions.subscription.voucherUid = voucher;
    setIsItFinished(true);
    setFinalResult(true);
  };
  //////////////////////////////////////////////////////////////////
  ////////        Stripe CheckOut and Final Validation     ////////
  ////////////////////////////////////////////////////////////////
  // Spinner Div hide
  var mainDiv = document.getElementById("main");

  // const handleTokenPaypal = (data) => {
  //     console.log("data object before subscription ")
  //     console.log(data)
  //     console.log(subscriptions)
  //     let beginAt = new Date();
  //     let endAt = beginAt;
  //     subscriptions.createdAt = new Date().getTime();
  //     subscriptions.updatedAt = new Date().getTime();
  //     subscriptions.beginAt = new Date(beginAt).getTime();
  //     subscriptions.endAt = new Date(endAt.setMonth(endAt.getMonth() + 12)).getTime();
  //     if (data.subscriptionID) {
  //         formData.paypalCustomerId = data.facilitatorAccessToken;
  //     }
  //     subscriptions.subscription = data.subscriptionID;
  //     subscriptions.type = 'paypal';
  //     // formData.subscriptions = subscriptions;

  //     console.log('subscription de paypal', data.subscription);
  //     setFinalResult(true)
  // }
  //     const stripe = useStripe();
  // const elements = useElements();

  // const handleSubmitSubStripe = async (event) => {
  //     if (!stripe || !elements) {
  //       // Stripe.js has not yet loaded.
  //       // Make sure to disable form submission until Stripe.js has loaded.
  //       return;
  //     }

  //     const result = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: elements.getElement(CardElement),
  //       billing_details: {
  //         email: formData.email,
  //       },
  //     });

  //     if (result.error) {
  //       console.log(result.error.message);
  //     } else {
  //       const res = await axios.post('http://localhost:3000/sub', {'payment_method': result.paymentMethod.id, 'email': email});
  //       // eslint-disable-next-line camelcase
  //       const {client_secret, status} = res.data;

  //       if (status === 'requires_action') {
  //         stripe.confirmCardPayment(client_secret).then(function(result) {
  //           if (result.error) {
  //             console.log('There was an issue!');
  //             console.log(result.error);
  //             // Display error message in your UI.
  //             // The card was declined (i.e. insufficient funds, card has expired, etc)
  //           } else {
  //             console.log('You got the money!');
  //             // Show a success message to your customer
  //           }
  //         });
  //       } else {
  //         console.log('You got the money!');
  //         // No additional information was needed
  //         // Show a success message to your customer
  //       }
  //     }
  //   };
  const handleTokenPaypal2 = async (data, subscription) => {
    console.log("data object before subscription ", data);
    console.log(subscription);
    // Spinner Div hide
    mainDiv.classList.add("hide");
    // SetUp Subscription Data
    let beginAt = new Date();
    let endAt = beginAt;
    subscriptions.createdAt = new Date().getTime();
    subscriptions.updatedAt = new Date().getTime();
    subscriptions.beginAt = new Date(beginAt).getTime();
    subscriptions.endAt = new Date(
      endAt.setMonth(endAt.getMonth() + 12)
    ).getTime();
    if (subscription) subscriptions.subscription = subscription.subscription;
    subscriptions.type = "paypal";
    // formData.subscriptions = subscriptions;
    if (Africa.includes(formData.region))
      dataTest.data.models.MMPartner = "Africa";
    else if (SouthAsia.includes(formData.region))
      dataTest.data.models.MMPartner = "India";
    else if (NorthAmerica.includes(formData.region))
      dataTest.data.models.MMPartner = "America";
    console.log("subscription de paypal", subscription);
    if (active) {
      dataTest.users.TalentLab = true;
    }
    setIsItFinished(true);
    setFinalResult(true);
  };

  const handleToken = async (status, subscription) => {
    setStripeIsOpen(false);
    console.log("THIS IS STATUS", status);
    console.log("THIS IS SUB", subscription);
    console.log("THIS IS sub status old ", subscription.status);

    if (subscription.status !== "active") {
      subscription = await axios.post(
        "https://us-central1-mymotheragency-208817.cloudfunctions.net/checkoutSocial/retrieve",
        { subscriptionId: subscription.id }
      );
      subscription = subscription.data;
      console.log("THIS IS NEW SUB OBJECT", subscription);
      console.log("THIS IS NEW SUB STATUS", subscription.status);
    }
    // Spinner Div hide
    mainDiv.classList.add("hide");

    // SetUp Subscription Data
    let beginAt = new Date();
    let endAt = beginAt;
    subscriptions.createdAt = new Date().getTime();
    subscriptions.updatedAt = new Date().getTime();
    subscriptions.beginAt = new Date(beginAt).getTime();
    subscriptions.endAt = new Date(
      endAt.setMonth(endAt.getMonth() + 12)
    ).getTime();

    if (subscription.customer) {
      formData.stripeCustomerId = subscription.customer;
    }
    subscriptions.subscription = subscription;
    subscriptions.type = "stripe";
    if (Africa.includes(formData.region))
      dataTest.data.models.MMPartner = "Africa";
    else if (SouthAsia.includes(formData.region))
      dataTest.data.models.MMPartner = "India";
    else if (NorthAmerica.includes(formData.region))
      dataTest.data.models.MMPartner = "America";
    console.log("subscription de stripe", subscription);
    if (active) {
      dataTest.users.TalentLab = true;
    }
    setIsItFinished(true);
    setFinalResult(true);
  };

  //////////////////////////////////////////////////////////////////
  ////////    Loading Component After Payment Validation   ////////
  ////////////////////////////////////////////////////////////////
  // create a list of all european countries
  const Europe = [
    "Albania",
    "Andorra",
    "Armenia",
    "Austria",
    "Azerbaijan",
    "Belarus",
    "Belgium",
    "Bosnia and Herzegovina",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Georgia",
    "Germany",
    "Greece",
    "Hungary",
    "Iceland",
    "Ireland",
    "Italy",
    "Kosovo",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia",
    "Malta",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Russia",
    "San Marino",
    "Serbia",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Turkey",
    "Ukraine",
    "United Kingdom",
    "Vatican City",
  ];
  const NorthAmerica = [
    "Canada",
    "USA",
    "United states",
    "United states of America",
  ];
  const Africa = [
    "Côté d’Ivoire",
    "Sénégal",
    "Guinée Conakry",
    "Ghana",
    "Congo Brazzaville",
    "République démocratique du Congo",
    "Rwanda",
    "Liberia",
    "Cameroun",
    "Tunisie",
    "Algérie",
    "Maroc",
  ];
  const SouthAsia = [
    "Inde",
    "Malaisie",
    "Singapour",
    "Pakistan",
    "Bangladesh",
    "Nepal",
    "Sri Lanka",
  ];

  const [active, setActive] = useState(false);
  const [priceClassic, setPriceClassic] = useState("35€");
  const [priceLab, setPriceLab] = useState("300€");
  //useeffect hook to load the component after payment validation
  useEffect(() => {
    if (formData.region) {
      if (NorthAmerica.includes(formData.region)) {
        setPriceClassic("49$");
        setPriceLab("350$");
      } else if (SouthAsia.includes(formData.region)) {
      } else if (Africa.includes(formData.region)) {
        setPriceClassic("23000CFA");
        setPriceLab("197000CFA");
      }
    }
  }, [formData.region]);

  const spinner = () => {
    // const forms = document.getElementById('content');
    // forms.classList.add("hide");
    return (
      <>
        <div className="d-flex">
          <div className="spinner-border" role="status">
            <span className="hide">Loading...</span>
          </div>
        </div>
      </>
    );
  };
  const landingPage = (
    <>
      <div id="lastPage">
        <h2>{t("Register.congrats")}</h2>
        <p>{t("Register.congratsDesc")}</p>
        <button
          onClick={() => {
            window.location.href = "https://onelink.to/yvewaa";
          }}
          type="button"
        >
          {t("Register.goToApp")}
        </button>
      </div>
    </>
  );
  const [isOpen, setIsOpen] = useState(false);
  //stripe open is assigned but is never used
  const [stripeIsOpen, setStripeIsOpen] = useState(false);
  const [textBtn1, setTextBtn1] = useState(t("Register.packBtn"));
  const [textBtn2, setTextBtn2] = useState(t("Register.packBtn"));
  const [ppPlanId, setPpPlanId] = useState("P-8PE50048JF9406144MKYN7ZY");
  const [stripePlan, setStripePlan] = useState("plan_FNFbFW6DjaNtIe");
  const [showGodFather, setShowGodFather] = useState(false);
  useEffect(() => {
    if (active) {
      setTextBtn1(t("Register.packBtn"));
      setTextBtn2(t("Register.packBtnSelected"));
    } else {
      setTextBtn1(t("Register.packBtnSelected"));
      setTextBtn2(t("Register.packBtn"));
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      if (NorthAmerica.includes(formData.region)) {
        setPpPlanId("P-9MU762897A4722231MKSOQPA");
        setStripePlan("price_1L9ZjxEehZRn7Ri6QIEW6wNO");
      } else if (SouthAsia.includes(formData)) {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y");
        setStripePlan("price_1L9ZlyEehZRn7Ri6uMVB9lCz");
      } else if (Africa.includes(formData.region)) {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y ");
        setStripePlan("price_1LASw5EehZRn7Ri6YHXnXuZu");
      } else {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y");
        setStripePlan("price_1L9ZlyEehZRn7Ri6uMVB9lCz");
      }
    } else {
      if (NorthAmerica.includes(formData.region)) {
        setPpPlanId("P-1CH13741E6880750AMKYN6ZQ");
        setStripePlan("price_1L7zVBEehZRn7Ri6LUsJatf4");
      } else if (SouthAsia.includes(formData.region)) {
        setPpPlanId("P-8PE50048JF9406144MKYN7ZY");
        setStripePlan("plan_FNFbFW6DjaNtIe");
      } else if (Africa.includes(formData.region)) {
        setPpPlanId("P-3UB48616SY024182KMJIVEZY");
        setStripePlan("price_1LATeBEehZRn7Ri6lYuCZV3v");
      } else {
        setPpPlanId("P-8PE50048JF9406144MKYN7ZY");
        setStripePlan("plan_FNFbFW6DjaNtIe");
      }
    }
  }, [formData.region]);

  useEffect(() => {
    if (active) {
      if (NorthAmerica.includes(formData.region)) {
        setPpPlanId("P-9MU762897A4722231MKSOQPA");
        setStripePlan("price_1L9ZjxEehZRn7Ri6QIEW6wNO");
      } else if (SouthAsia.includes(formData)) {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y");
        setStripePlan("price_1L9ZlyEehZRn7Ri6uMVB9lCz");
      } else if (Africa.includes(formData.region)) {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y ");
        setStripePlan("price_1L9ZlyEehZRn7Ri6uMVB9lCz");
      } else {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y");
        setStripePlan("price_1L9ZlyEehZRn7Ri6uMVB9lCz");
      }
    } else {
      if (NorthAmerica.includes(formData.region)) {
        setPpPlanId("P-1CH13741E6880750AMKYN6ZQ");
        setStripePlan("price_1L7zVBEehZRn7Ri6LUsJatf4");
      } else if (SouthAsia.includes(formData.region)) {
        setPpPlanId("P-8PE50048JF9406144MKYN7ZY");
        setStripePlan("plan_FNFbFW6DjaNtIe");
      } else if (Africa.includes(formData.region)) {
        setPpPlanId("P-3UB48616SY024182KMJIVEZY");
        setStripePlan("price_1KoQ6EEehZRn7Ri6Uove1Pfl");
      } else {
        setPpPlanId("P-8PE50048JF9406144MKYN7ZY");
        setStripePlan("plan_FNFbFW6DjaNtIe");
      }
    }
  }, [active]);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const toggleStripe = () => {
    togglePopup();
  };

  return (
    <div>
      <div className="formContainer">
        <div className="left-area">
          <img src={slideImg} alt="slideImg" />
        </div>
        <div
          className="form"
          style={windowDimensions.width > 768 ? { position: "relative" } : null}
        >
          <img src={logo} alt="logo" />
          {isItFinished ? (
            landingPage
          ) : (
            <>
              <div id={"content"}>
                {finalResult ? spinner() : null}
                <div id={"main"} className="">
                  {searchParams.get("uid") &&
                  searchParams.get("firstName") &&
                  searchParams.get("lastName")
                    ? null
                    : form1And2}

                  <div
                    id="form_three"
                    className="formThree forms"
                    style={step === 2 ? opacity : null}
                    hidden={step < 2 || step > 3}
                  >
                    <div id="formThreeName">
                      <span className="stepNumber">03</span>
                      <h2>{t("Register.thirdStep")}</h2>
                    </div>

                    <form onSubmit={handleSubmit3(onSubmit3)}>
                      <FormGroup>
                        <div className="inputContainer">
                          {/* <Input
                                            id="country" placeholder=t("Register.locationClick")
                                            style={
                                                region !== "" ? {borderRadius: "14px 14px 0 0"} : null
                                            }
                                            {...register3("position", {
                                                required: "Position is required",
                                            })}
                                        /> */}
                          <PlacesAutocomplete
                            value={addresse}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            searchOptions={{ types: ["country"] }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    id: "country",
                                    placeholder: "",
                                    className: "location-search-input",
                                    // register:register3('position', {
                                    //     required: 'Position is required'
                                    // })
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Chargement...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#34393e",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#34393e",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>

                          <div onClick={() => findPays()} id="iconClick">
                            {" "}
                            <MyLocationIcon
                              className="icon"
                              style={{ cursor: "pointer" }}
                            />
                          </div>

                          <div className="label">{t("Register.location")}</div>
                          <div
                            style={region === "" ? { display: "none" } : null}
                            className="region"
                          >
                            <div>{region}</div>
                            <HighlightOffIcon
                              onClick={() => setRegion("")}
                              style={{ cursor: " pointer" }}
                            />{" "}
                          </div>

                          {/*
                                                <div
                                                    style={{
                                                        height: "200px",
                                                        width: "80%",
                                                        margin: "auto",
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    <GoogleMapReact
                                                        hidden={true}
                                                        onClick={_onClick}
                                                        center={center}
                                                        defaultZoom={10}
                                                        bootstrapURLKeys={{
                                                            key: "AIzaSyB6znF7jDzYiQeSCHGMIF0rLSL6qktnlrE",
                                                        }}
                                                    ></GoogleMapReact>
                                                </div>

                                            */}

                          {/*<div className="error">
                                                {errorPosition && t("Register.locationError")}
                                            </div>
                                            <div className="error">
                                                {errorRegion && t("Register.locationInvalid")}
                                            </div>*/}
                          <div className="error">
                            {errorAddresse && t("Register.locationHint")}
                          </div>
                        </div>

                        {/*<div className="error">
                                            {errors3.position && errors3.position.message}
                                        </div>*/}

                        <div hidden={step === 2} className="inputContainer">
                          <Input
                            id="genderSelect"
                            name="selectGender"
                            type="select"
                            value={gender}
                            onChange={handleSelectGender}
                            required={true}
                          >
                            <option value="" hidden></option>
                            <option value={"man"}>
                              {t("Register.genderMale")}
                            </option>
                            <option value={"woman"}>
                              {t("Register.genderFemale")}
                            </option>
                            <option value={"transgender"}>
                              {t("Register.genderTrans")}
                            </option>
                          </Input>
                          <div className="label">{t("Register.gender")}</div>

                          <div className="error"></div>
                        </div>

                        <div hidden={step === 2} className="inputContainer">
                          <input
                            type="date"
                            id="birth"
                            name="date"
                            // pattern="/^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g"
                            required={true}
                            style={{ width: "100%" }}
                            placeholder="jj/mm/aaaa"
                            // onChange={(date) => setDateNaissance(date)}
                            // onChange={(date)=> console.log(date)}
                          />
                          <div className="label-champ">
                            {t("Register.birthDateHint")}
                          </div>

                          <DateRangeIcon
                            style={{ pointerEvents: "none" }}
                            className="icon"
                          />
                          <div className="label">{t("Register.birthDate")}</div>
                        </div>
                        <div id="error_birth" className="error">
                          {errorAge && t("Register.birthDateInvalid")}
                        </div>

                        <button id="form3" className="hideButton"></button>
                      </FormGroup>
                    </form>
                  </div>

                  <div
                    id="form_four"
                    className="formFour forms"
                    style={step === 3 ? opacity : null}
                    hidden={step < 3 || step > 4}
                  >
                    <div id="formFourName">
                      <span className="stepNumber">04</span>
                      <h2>{t("Register.fourthStep")}</h2>
                    </div>

                    <div className="scroll">
                      <Carousel
                        showArrows={false}
                        swipeable={true}
                        showStatus={false}
                        showThumbs={false}
                        emulateTouch={true}
                        autoPlay={false}
                        centerMode={true}
                        centerSlidePercentage={90}
                      >
                        <div className="offreDesc">
                          <div className={"centred"}>
                            <h2>
                              talents classic
                              <img src={classicImg} alt="Classic" />
                            </h2>
                          </div>

                          <p className="title">
                            <strong>{t("Register.pack1Title1")}</strong>
                          </p>
                          <p>{t("Register.pack1Description1")} </p>

                          <p className="title">
                            <strong>{t("Register.pack1Title2")}</strong>
                          </p>
                          <p> {t("Register.pack1Description2")}</p>

                          <p className="title">
                            <strong>{t("Register.pack1Title3")}</strong>
                          </p>
                          <p> {t("Register.pack1Description3")}</p>

                          <p className="title">
                            <strong>{t("Register.pack1Title4")}</strong>
                          </p>
                          <p> {t("Register.pack1Description4")}</p>

                          <div>
                            <h4 style={{ color: "white", marginLeft: 120 }}>
                              {priceClassic}
                              {t("Register.pack1Prix")}
                            </h4>
                            <div className={"centred"}>
                              <button
                                className={
                                  !active ? "toChooseButton" : "sendButtonGrey"
                                }
                                onClick={() => setActive(false)}
                                type="button"
                              >
                                {textBtn1}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="offreDesc">
                          <div className={"centred"}>
                            <h2>
                              talents lab
                              <img src={talentsImg} alt="Talentimg" />
                            </h2>
                          </div>
                          <p className="title">
                            <strong>{t("Register.pack2Title1")}</strong>
                          </p>
                          <p>{t("Register.pack2Description1")}</p>
                          <p className="title">
                            <strong>{t("Register.pack2Title2")}</strong>
                          </p>
                          <p>{t("Register.pack2Description2")}</p>
                          <p className="title">
                            <strong>{t("Register.pack2Title3")}</strong>
                          </p>
                          <p>{t("Register.pack2Description3")}</p>
                          <div>
                            <h4 style={{ color: "white", marginLeft: 120 }}>
                              {priceLab}
                              {t("Register.pack2Prix")}
                            </h4>
                            <div className={"centred"}>
                              <button
                                className={
                                  active ? "toChooseButton" : "sendButtonGrey"
                                }
                                type="button"
                                onClick={() => setActive(true)}
                              >
                                {textBtn2}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Carousel>
                    </div>
                  </div>
                  <div
                    id="form_five"
                    className="formFive forms"
                    hidden={step < 4}
                    style={step === 4 ? opacity : null}
                  >
                    <div id="formFiveName">
                      <span className="stepNumber">05</span>
                      <h2>{t("Register.fifthStep")}</h2>
                    </div>

                    <form onSubmit={handleSubmit4(onSubmit4)}>
                      <div className="inputContainer">
                        <Input
                          className="inputContainer"
                          id="GodFatherCheckbox"
                          name="Godfather"
                          type="checkbox"
                          onClick={(e) => setGodfatherBtn(!godfatherBtn)}
                          style={{ height: "auto", width: "10%" }}
                        />
                        <span>{t("Register.agency")}</span>
                      </div>
                      <FormGroup row>
                        <div
                          className="inputContainer"
                          style={{ display: godfatherBtn ? "block" : "none" }}
                        >
                          <input
                            name="godfatherid"
                            type="text"
                            {...register4("godfatherid")}
                          />
                          <div className="label-champ">
                            {t("Register.referral")}
                          </div>
                          <div className="label">
                            {t("Register.referralTitle")}
                          </div>
                          <div className="error">
                            {errors4.godfatherid && t("Register.referralError")}
                          </div>
                        </div>

                        <div className="inputContainer">
                          <input
                            name="voucher"
                            type="text"
                            {...register4("voucher")}
                            onChange={(e) => _checkVoucher(e)}
                          />
                          <div className="label-champ">
                            {t("Register.invitationCodeTitle")}
                          </div>
                          <div className="label">
                            {t("Register.invitationCode")}
                          </div>
                          <div className="error">
                            {errors4.voucher && t("Register.invitationError")}
                          </div>
                        </div>

                        <div className="inputContainer">
                          <Input
                            type="textarea"
                            name="CGV"
                            rows={9}
                            style={{
                              width: "100%",
                              borderRadius: "1vh",
                              paddingLeft: "0.4em",
                            }}
                            value={
                              t("Register.textCGV") +
                              t("Register.textCGV2") +
                              t("Register.textCGV3") +
                              t("Register.textCGV4") +
                              t("Register.textCGV5") +
                              t("Register.textCGV6")
                            }
                            readOnly={true}
                          />
                        </div>

                        <div className="inputContainer">
                          <Input
                            name="cgu"
                            type="checkbox"
                            id="CguCheckbox"
                            defaultChecked={false}
                            onClick={(e) => setCguBtn(!cguBtn)}
                            style={{
                              height: "auto",
                              width: "10%",
                              zIndex: "11",
                            }}
                            // {...register4("cgu", {required: 'This is required'})}
                          />
                          <span style={{ color: !cguBtn ? "red" : "white" }}>
                            {t("Register.CGV")}
                          </span>
                        </div>
                      </FormGroup>
                    </form>
                  </div>
                </div>
                <div className={`centred ${cguBtn ? "fadeIn" : "fadeOut"}`}>
                  {cguBtn && !isValidVoucher && (
                    <div className="sendButton" onClick={togglePopup}>
                      {isOpen && (
                        <Popups
                          content={
                            <>
                              {/* <StripeCheckout
                                            label="pay"
                                            className="sendButton"
                                            stripeKey='pk_test_MMk6QzjFuJS3HgZIYTVAl9TI'
                                            billingAddress
                                            shippingAddress
                                            email={formData.email}
                                            amount={35 * 100}
                                            name={"Talens classic"}
                                            token={handleToken}
                                            currency="EUR"
                                        >
                                            
                                        </StripeCheckout> */}
                              <Popup
                                trigger={
                                  <button
                                    className="stripeBtn"
                                    // type="submit"
                                    onClick={toggleStripe}
                                  >
                                    {t("Register.PayBtnStripe")}
                                  </button>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div className="modal">
                                    <button className="close" onClick={close}>
                                      &times;
                                    </button>

                                    <div className="content">
                                      <Elements stripe={stripePromise}>
                                        <Stripes
                                          handleToken={handleToken}
                                          email={formData.email}
                                          name={
                                            formData.firstName +
                                            formData.lastName
                                          }
                                          plan={stripePlan}
                                        />
                                      </Elements>
                                    </div>
                                  </div>
                                )}
                                {/* <Elements stripe={stripePromise}>
                                                            <Stripes
                                                                handleToken={props.handleToken}
                                                                billingAddress={props.billingAddress}
                                                                email={props.email}
                                                            />
                                                        </Elements> */}
                              </Popup>
                              <Paypal
                                handleTokenPaypal2={handleTokenPaypal2}
                                plan={ppPlanId}
                              />
                            </>
                          }
                          handleClose={togglePopup}
                        />
                      )}
                      {t("Register.PayBtn")}
                    </div>
                  )}
                  {cguBtn && isValidVoucher && (
                    <button
                      className="sendButton"
                      // type="submit"
                      onClick={sendVoucher}
                    >
                      {t("Register.PayBtn")}
                    </button>
                  )}
                </div>

                <div
                  className={`footer ${
                    cguBtn &&
                    document.getElementById("numStep").innerText === "5"
                      ? "fadeOut"
                      : "fadeIn"
                  }`}
                >
                  <div id="taskBar">
                    <div className="footerText">
                      {t("Register.step")} &nbsp;
                      <div id="numStep"> 1</div>
                      &nbsp;/ 5
                    </div>
                    <div className="answered">
                      <div
                        className="firstStep"
                        style={step >= 2 ? style1 : null}
                      ></div>
                      <div
                        className="test"
                        style={step === 2 ? style2 : step > 2 ? style3 : null}
                      ></div>
                      <div
                        style={step === 3 ? style2 : step > 3 ? style3 : null}
                      ></div>
                      <div
                        style={step === 4 ? style2 : step > 4 ? style3 : null}
                      ></div>
                      <div style={step >= 5 ? style2 : null}></div>
                    </div>
                  </div>

                  <div
                    id="arrow-suitch"
                    className="arrow"
                    onClick={arrowFunction}
                  >
                    <div id="arrow" className="ok">
                      {" "}
                      &#8595;
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Forms;
