import React from "react";
import axios from "axios";
//MUI Components
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import image from "../../assets/pbStripe.png";
import image2 from "../../assets/accept.png";
import CardContent from "@material-ui/core/CardContent";
// stripe
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
// Util imports
import { makeStyles } from "@material-ui/core/styles";
import CardInput from "./CardInput";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    backgroundColor: "#34393e",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    backgroundColor: "#34393e",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    margin: "2em auto 1em",
    color: "white",
    backgroundColor: "#e62375",
  },
});

function Stripes(props) {
  const classes = useStyles();
  // State

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmitSub = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const results = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: props.name,
        email: props.email,
      },
    });
    console.log(results);
    if (results.error) {
      console.log(results.error.message);
    } else {
      const res = await axios.post(
        "https://us-central1-mymotheragency-208817.cloudfunctions.net/checkoutSocial",
        {
          payment_method: results.paymentMethod.id,
          email: props.email,
          name: props.name,
          plan: props.plan,
        }
      );
      // eslint-disable-next-line camelcase
      const { client_secret, status, subscription } = res.data;
      console.log(client_secret, status);
      if (
        status.includes("succeeded") ||
        status.includes("success") ||
        status.includes("trial")
      ) {
        console.log("You got the money!");
        alert("Successfully subscribed! , redirecting ...");
        props.handleToken(status, subscription);
      } else {
        await stripe
          .confirmCardPayment(client_secret)
          .then(async function (result) {
            console.log(result);
            if (result.error) alert(result.error.message);
            else {
              // use "===" to compare the value
              if (
                result.paymentIntent.status === "succeeded" ||
                result.paymentIntent.status === "success"
              ) {
                console.log(result);
                console.log("You got the money!");
                console.log(subscription);
                alert("Successfully subscribed! , redirecting ...");
                //call handleToken method from props

                return props.handleToken(status, subscription);
              } else {
                console.log("There was an issue!");
                console.log(result.error);
                if (result.error.payment_intent.last_payment_error.message)
                  alert(result.error.payment_intent.last_payment_error.message);
                else alert("There was an issue!Please try again later.");
              }
            }
          });
      }
    }
  };

  return (
    <Card className={classes.root}>
      <img
        src={image}
        height={50}
        width={173}
        style={{ display: "block", margin: "auto" }}
      />
      <CardContent className={classes.content}>
        <CardInput />
        <div className={classes.div}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleSubmitSub}
          >
            Subscription
          </Button>
        </div>
      </CardContent>
      <img
        src={image2}
        height={100}
        width={700}
        style={{
          display: "block",
          margin: "auto",
          width: "50%",
          height: "50%",
        }}
      />
    </Card>
  );
}

export default Stripes;
