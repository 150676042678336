import Forms from "./Components/Form/Forms";
import {
  BrowserRouter,
  Routes,
  Route,
  // useParams,
} from "react-router-dom";

// function ProfilePage() {
//   // Get the userId param from the URL.
//   let { uid, firstName, lastName } = useParams(); // Returns the value of parameters passed through the URL
//   // ...
// }

//// Test for URL -> https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams
// var url = new URL('https://example.com?foo=1&bar=2'); 
// var params = new URLSearchParams(url.search);

<Route path="/" element={<Forms social={true} fromSocial="Apple" uid='123456789' firstName="George" lastName="Delajungle" />} />


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Forms />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
